@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600&display=swap');

* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;

  margin: 0;
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #131415;
  color: #ececec;
}

#root {
  height: 100%;
  width: 100%;
}