:root {
  --slide-direction: 1;
}

* {
  --panel-color: rgba(14, 26, 33, 0.5);
  --min-opacity: 0;
  --body-font: "Space Grotesk", "Roboto", sans-serif;
  --toastify-color-dark: var(--panel-color);
  --toastify-color-error: rgba(255, 0, 0, 0.54);
  --toastify-font-family: var(--body-font);
  color-scheme: dark;
}

@supports not (backdrop-filter: blur(1px)) {
  * {
    --min-opacity: 0.85;
  }
}

.toast-blur {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

#hidden-plot {
  display: none;
  position: fixed;
  top: 200%;
  left: 200%;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-basis: auto;
  text-align: center;
  font-size: 20px;
  height: 26px;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header-link {
  cursor: pointer;
  border-radius: 0.2rem;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color;
}

.header-link:hover {
  background-color: rgba(0, 0, 0, 0.27);
}

.header-link::after {
  content: "▼";
  padding-left: 0.5rem;
  font-size: 0.75em;
}

.server-panel {
  text-align: left;
  flex: 1 1;
  font-variant-numeric: tabular-nums;
  white-space: nowrap;
}

.disconnect-button,
.reconnect-button {
  font-size: 0.75rem !important;
  height: 2rem !important;
  margin-left: 0.5rem;
  transform: translateY(-0.25rem);
}

.header-right {
  flex: 1 1;
  text-align: right;
}

.header,
.footer {
  z-index: 99;
  flex-basis: auto;
  background-color: #0e1a21;
  padding: 0.5rem;
  background-color: var(--panel-color);
  backdrop-filter: blur(10px);
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-size: 3rem;
  color: #fff;
}

.game-container {
  flex-grow: 1;
  position: relative;
}

a {
  color: rgb(222, 234, 248);
}

.layout-container {
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.bg-plot-container {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.bg-plot-container {
  z-index: 1;
}

.plot {
  width: 100%;
  height: 100%;
}

.gl-canvas {
  z-index: -1;
  transform-origin: var(--plot-center-x) var(--plot-center-y);
}

.js-plotly-plot .plotly .modebar {
  left: 30px;
  right: auto !important;
  top: 44px !important;
}

.layout-container {
  z-index: 2;
  pointer-events: none;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  align-content: stretch;
}

.guess-form *,
.guess-list,
.solved-container {
  pointer-events: all;
}

.guess-form {
  text-align: right;
}

.guess-container {
  /*background-color: rgba(231, 4, 4, 0.16);*/
  align-self: baseline;
  padding: 1rem;
  flex-basis: 30rem;
}

.guess-input,
input[type="button"],
input[type="submit"] {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  background-color: rgba(14, 26, 33, 0.77);
  color: rgb(236, 236, 236);
  box-shadow: none;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  border-color: rgba(236, 236, 236, 0.5);
  padding: 0.5rem;
  font-family: var(--body-font);
  backdrop-filter: blur(3px);
}

input[type="button"],
input[type="submit"] {
  height: 3rem;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  position: relative;
  width: 2rem;
  height: 1.2rem;
  background: rgba(14, 26, 33, 0.77);
  border-radius: 2rem;
  border-width: 0.1rem;
  border-style: solid;
  transition: background-color 0.4s;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  width: 1rem;
  height: 1rem;
  background: #ececec;
  border-radius: 1rem;
  transition: 0.4s;
}

input:checked + .switch-label {
  background: rgba(222, 234, 248, 0.6);
}

input:checked + .switch-label .switch-button {
  left: calc(100% - 0.1rem);
  transform: translateX(-100%);
}

.guess-setting {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.guess-input {
  height: 2rem;
  width: 15rem;
  margin-right: 0.5rem;
}

.hint-button {
  background-color: rgba(14, 54, 20, 0.58) !important;
}

.give-up-button {
  background-color: rgba(54, 14, 14, 0.58) !important;
}

.guess-list {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 75vh;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column-reverse;
}

.guess-entry {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  backdrop-filter: blur(10px);
  --outline-color: rgba(0, 0, 0, 0.5);
  --outline-offset: 0.1rem;
  --outline-blur: 0.5rem;
  text-shadow: calc(-1 * var(--outline-offset)) calc(-1 * var(--outline-offset))
      var(--outline-blur) var(--outline-color),
    0 calc(-1 * var(--outline-offset)) var(--outline-blur) var(--outline-color),
    var(--outline-offset) calc(-1 * var(--outline-offset)) var(--outline-blur)
      var(--outline-color),
    var(--outline-offset) 0 var(--outline-blur) var(--outline-color),
    var(--outline-offset) var(--outline-offset) var(--outline-blur)
      var(--outline-color),
    0 var(--outline-offset) var(--outline-blur) var(--outline-color),
    calc(-1 * var(--outline-offset)) var(--outline-offset) var(--outline-blur)
      var(--outline-color),
    calc(-1 * var(--outline-offset)) 0 var(--outline-blur) var(--outline-color);
  color: rgba(255, 255, 255, 0.9);
  --border-color: rgba(255, 255, 255, 0.3);
  border: 1px solid var(--border-color);
}

.bg-correct {
  background-color: rgba(26, 255, 199, calc(max(0.9, var(--min-opacity))));
  --border-color: rgba(26, 255, 199, 0.7);
  color: black;
  --outline-color: rgba(255, 255, 255, 0.5);
  --outline-radius: 0;
}

.bg-very-hot {
  background-color: rgba(250, 252, 238, calc(max(0.8, var(--min-opacity))));
  --border-color: rgba(250, 252, 238, 0.7);
  color: black;
  --outline-color: rgba(255, 255, 255, 0.5);
}

.bg-quite-hot {
  background-color: rgb(236, 207, 18, calc(max(0.7, var(--min-opacity))));
  --border-color: rgba(236, 207, 18, 0.3);
  color: black;
  --outline-color: rgba(255, 255, 255, 0.5);
}

.bg-hot {
  background-color: rgba(250, 50, 4, calc(max(0.6, var(--min-opacity))));
  --border-color: rgba(250, 50, 4, 0.3);
}

.bg-toasty {
  background-color: rgba(196, 0, 0, calc(max(0.5, var(--min-opacity))));
  --border-color: rgba(196, 0, 0, 0.7);
}

.bg-warm {
  background-color: rgba(126, 0, 38, calc(max(0.4, var(--min-opacity))));
  --border-color: rgba(126, 0, 38, 0.7);
}

.bg-tepid {
  background-color: rgba(52, 0, 80, calc(max(0.3, var(--min-opacity))));
  --border-color: rgba(80, 0, 126, 0.7);
}

.bg-cold {
  background-color: rgba(5, 0, 59, calc(max(0.25, var(--min-opacity))));
  --border-color: rgba(0, 28, 161, 0.5);
}

.bg-frigid {
  background-color: rgba(0, 12, 30, calc(max(0.2, var(--min-opacity))));
  --border-color: rgba(30, 38, 230, 0.4);
  background-blend-mode: multiply;
}

.guess-hint {
  border-style: solid;
  border-width: 0.1rem;
  border-color: greenyellow;
}

.guess-index {
  flex-basis: 2rem;
  font-variant-numeric: tabular-nums;
}

.guess-word {
  flex-grow: 1;
}

.guess-similarity {
  flex-basis: 1rem;
  font-variant-numeric: tabular-nums;
}

.guess-rank {
  flex-basis: 3rem;
  text-align: right;
}

.guess-rank-placeholder {
  flex-basis: 3rem;
  text-align: center;
}

.guess-flavor {
  flex-basis: 4rem;
  text-align: right;
  text-wrap: nowrap;
}

.slide-in {
  animation: slide-in 0.5s ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateX(calc(120% * var(--slide-direction)));
    max-height: 0;
  }
  to {
    transform: translateX(0%);
    max-height: 100px;
  }
}

.safe-container {
  pointer-events: none;
  /*background-color: rgba(0, 255, 4, 0.1);*/
  border-radius: 1rem;
  margin: 1rem;
  flex-grow: 1;
}

hr {
  margin-bottom: 0.5em;
  opacity: 0.5;
}

.congrats-text {
  font-variant-numeric: tabular-nums;
}

.archive-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  pointer-events: none;
}

.archive-open {
  pointer-events: all;
}

.archive-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: -1;
  transition: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.archive-open > .archive-background {
  background-color: rgba(0, 0, 0, 0.83);
}

.archive-container {
  display: flex;
  background-color: var(--panel-color);
  backdrop-filter: blur(20px);
  border-radius: 2rem;
  padding: 5rem;
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 95%;
  max-height: calc(95% - 5rem);
  overflow-y: auto;
  transition: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  justify-content: center;
}

.archive-open > .archive-container {
  top: 5rem;
}

.archive-column {
  max-width: 25rem;
  padding: 2rem;
}

.close-button {
  position: absolute;
  left: 2rem;
  top: 0.5rem;
  font-size: 2rem;
  cursor: pointer;
}

.archive-heading {
  margin-top: 1rem;
}

.archive-subheading {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.archive-puzzle {
  display: flex;
  text-decoration: none;
  background-color: rgba(0, 61, 199, 0.3);
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  transition: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
  align-items: center;
}

.archive-puzzle:hover {
  background-color: rgba(0, 61, 199, 1);
}

.tile-star {
  flex-basis: 3rem;
  font-size: 2rem;
  text-align: center;
}

.solved-star {
  color: yellow;
}

.unsolved-star {
  color: rgba(255, 234, 0, 0.29);
}

.tile-title {
  flex-grow: 1;
  font-size: 2rem;
  margin-right: 0.25rem;
}

.semantle-footnote {
  font-size: 0.75rem;
}

.archive-footer {
  margin-left: 1.5rem;
}

.kofi-iframe {
  border: none;
  width: 100%;
  padding: 0;
  max-height: 600px;
  min-width: 260px;
  background: white;
  filter: invert(100%);
}

.tile-guesses {
  text-align: right;
}

.social-panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.social-button {
  width: 3rem;
  padding: 0.5rem;
  opacity: 0.7;
  transition: opacity;
  transition-duration: 0.2s;
}

.social-button:hover {
  opacity: 1;
}

@media (max-width: 1000px) {
  .archive-container {
    width: 99%;
    padding: 0;
    justify-content: start;
  }

  .archive-puzzle {
    padding: 0.25rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
  }

  .tile-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.background-canvas {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}

.stats-box {
  display: block;
  font-variant-numeric: tabular-nums;
  z-index: 1;
}

.stats-box table {
  border-collapse: collapse;
  margin: 0.5rem 0;
}

.stats-box td {
  text-align: right;
}

.stats-box th,
.stats-box td {
  padding: 0.25rem;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.stats-box input {
  font-size: 0.75rem !important;
  height: 2rem !important;
  margin-left: 1rem;
}

.sticky {
  position: sticky;
  top: 0;
}

summary {
  cursor: pointer;
}

@media (max-aspect-ratio: 1/1) {
  .layout-container {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .guess-container {
    flex-basis: 6rem;
    width: 50rem;
    max-width: 90vw;
  }

  .guess-form {
    text-align: left;
  }

  .guess-input {
    width: 30rem;
    max-width: calc(100vw - 15rem);
  }

  .guess-list {
    max-height: 40vh;
  }

  :root {
    --slide-direction: -1;
  }
}

@media (max-height: 700px) {
  body {
    font-size: 0.75rem;
  }

  .guess-entry {
    border-radius: 0;
    padding: 0.15rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .guess-list {
    max-height: 20vh;
  }

  .guess-input,
  input[type="button"],
  input[type="submit"] {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    border-width: 0.1rem;
    border-radius: 0.25rem;
    padding: 0.1rem;
  }

  input[type="button"],
  input[type="submit"] {
    height: 1.5rem;
  }

  .guess-input {
    height: 1.25rem;
    width: 15rem;
    margin-right: 0.5rem;
  }

  .guess-container {
    padding-bottom: 0;
  }
}
